.radioDefineTurma {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioDefineTurma input[type=radio] {
  outline: 0.3rem solid black;
  border: 0.3rem solid #fafafa;
  background-color: #fafafa;
  margin: 1rem;
  }
  
.radioDefineTurma input[type=radio]:checked {
  background-color: black; 
  }