.apresentacao {
    display: flex;    
    flex-direction: row; 
}

.leitor {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

.livros {
    display: flex;
    margin-top: 1rem;
}

.imgCentro {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centralizaLog {
    position: absolute;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    width: 50rem;
    height: 40rem;
    margin-left: -25rem; /* metade da largura */
    margin-top: -20rem; /* metade da altura */
    color: #320b86;
    border: 0.4rem solid #320b86; 
    border-radius: 2rem;
    top: 50%; 
    left: 50%;
}

.centralizaLog .txtTitulo {
    margin: 2.0rem; 
    font-size: 3.2rem; 
    font-weight: bold;
}

.centralizaLog label {
    font-size: 2rem;
}

.centralizaLog input {
    font-size: 2rem;
    width: 80%;
    margin-top: 2rem; 
    /*margin-bottom: 0.5rem;*/
}

.centralizaLog .containerBtnPub {
    display: flex; 
    width: 80%;
    justify-content: space-between;
}

.titulo {
    display: flex;     
    justify-content: center;
    margin: 2rem 0rem;
}

.logo {
    height: 6.5rem; 
    width: 6.5rem; 
}

.containerBtnAuxilio {
    display: flex;
    justify-content: right;
    width: 80%; 
    margin-bottom: 1rem;
}

.containerBtnAuxilio .btnAuxilio {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; 
    padding-right: 1rem;
    margin-left: 1rem; 
    color: #320b86; 
    text-decoration: none;
    font-size: 1.2rem; 
    white-space: nowrap;
  } 
  
.btnAuxilio:hover {
    cursor: pointer; 
    text-decoration: underline; /*usar underline no hover e cor no click*/
}

.btnAuxilio:active {
    color: #fafafa;  /*usar underline no hover e cor no click*/
}

.avisoErro {
    display: flex;
    align-items: center;
    color: coral; 
    margin-top: 2rem;
}

.centralizaCad {
    position: absolute;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    width: 44rem;
    height: 52rem;
    margin-left: -22rem; /* metade da largura */
    margin-top: -26rem; /* metade da altura */
    color: #320b86;
    border: 0.4rem solid #320b86; 
    border-radius: 2rem;
    top: 50%; 
    left: 50%;
}

.centralizaCad .txtTitulo {
    margin: 2.0rem; 
    font-size: 3.2rem; 
    font-weight: bold;
} 

.centralizaTerPol {
    position: absolute;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    width: 54rem;
    height: 52rem;
    margin-left: -27rem; /* metade da largura */
    margin-top: -26rem; /* metade da altura */
    color: #320b86;
    border: 0.4rem solid #320b86; 
    border-radius: 2rem;
    top: 50%; 
    left: 50%;
}

.centralizaTerPol .txtTituloTerPol {
    margin: 2rem; 
    font-size: 3.2rem; 
    font-weight: bold;
}

.tituloTerPol {
    display: flex;     
    justify-content: center;
    margin: 1rem 0rem;
}

.cadastro {
    display: flex; 
    flex-direction: column;
    width: 90%;
} 

.txtTerPol {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    width: 50rem;
    color: black;
    border: 0.4rem solid #320b86; 
    border-radius: 2rem;    
    padding: 0.5rem; 
}

.cadastro label {
    margin: 0rem; 
    font-size: 1.4rem; 
    line-height: normal;
} 

.cadastro input {
    margin: 0rem; 
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
}

.btnCadastro {
    width: 100%;
}

.containerSexo {
    display: flex; 
    border: 0.3rem solid #320b86; 
    border-radius: 2rem; 
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.containerSexo .containerOpSexo {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 1rem;
    height: 4rem;
    width: 100%;
}

.containerSexo .containerOpSexo input {
    width: 0.05rem;
    height: 0.05rem; 
    margin: 0rem 1rem; 
    outline: 0.3rem solid #320b86;
    border: 0.3rem solid #fafafa;
} 

.containerTextoTermos {
    /*width: 80%; */ 
    height: 16%;
    /*overflow: auto; */
    margin: 1rem 4rem;
}

.fonteTxa {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    font-size: 1.4rem; 
    line-height: normal; 
    font-weight: normal; 
    max-height: 22rem; 
    border: 0rem; 
    overflow: scroll; 
    padding: 1rem; 
    background-color: #fafafa;
}