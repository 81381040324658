.container {
    display: flex; 
    position: relative; 
    justify-content: center; 
    align-items: center;
    padding-bottom: 2%;
}

.container hr {
    position: absolute; 
    left: 0rem;
    width: 15rem; 
    border: 0.1rem solid #320b86;
    background-color: #320b86;
}

.container h1 {
    color: #320b86;
} 

@media (max-width: 800px) {
    .container hr {
        display: none;
    }
}