.containercb {
  background-color: #673ab7; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 100%; 
  border-bottom: 0.5rem solid #320b86; 
  position: fixed;
  z-index: 999;
  height: 8.0rem;
} 

.containercb .menu {
  width: 90%;
  max-width: 980px;
  height: 8.0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.0rem;    
}

.containercb .menu .titulo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containercb .menu .logo {
  height: 6.5rem; 
  width: 6.5rem; 
}

.containercb .menu .txtTitulo {
  margin: 2.0rem; 
  font-size: 3.2rem; 
  color: #fafafa; 
  font-weight: bold;
}

.containercb .menu .menuSection nav ul {
  display: flex;
}

.containercb .menu .menuSection nav ul li {
  list-style: none;
}

.containercb .menu .menuSection nav ul li a {
  text-decoration: none;
  color: #fafafa;
  text-transform: uppercase;
  padding: 1.4rem;
  transition: all 250ms linear 0s;
}

.containercb .menu .menuSection nav ul li a:hover {
  border-bottom: 0.5rem solid #320b86;
}

.containercb .menu .menuSection .MobileNavigation {
  display: none; 
}

/* usado para manter responsividade !atenção com a ordem, sempre do maior tamanho para o menor! */
@media (max-width: 960px) {
  .containercb .menu .menuSection .Navigation {
    display: none;  
  }  
  
  .containercb .menu .menuSection .MobileNavigation {
    display: flex;  
    align-items: center;
  }

  .containercb .menu .menuSection .MobileNavigation .Hamburger {
    position: absolute; 
    right: 2%;
    cursor: pointer;
  }

  .containercb .menu .menuSection nav ul {
    display: flex;
    flex-direction: column; 
    position: absolute; 
    top: 8rem; 
    right: 0rem; 
    width: 100%; 
    background: #673ab7; 
  }

  .containercb .menu .menuSection ul li {
    padding-top: 1rem;
    padding-left: 8%; 
    height: 4rem;
  } 

  .containercb .menu .menuSection nav ul li a {
    height: 10rem; 
    margin-top: 4rem;
    padding: 0rem;
  } 

  .containercb .menu .menuSection {
    opacity: 1;
  }

  .containercb .menu .menuSection nav ul li a:hover {
    border-bottom: none; 
    color: #9a67ea;
  }
}