.txt_lbl_s {
  color: lightblue;
  font-size: x-large;
  font-family: monospace;
}

.txt_lbl_n {
  /*color: lightblue;*/
  font-size: x-large;
  font-family: monospace;
}

.txt_lbl_espaco {
  font-size: x-large;
  font-family: monospace;
}

/*
  https://www.maujor.com/tutorial/propriedades-css-para-estilizacao-de-fontes.php
*/

.container_principal {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.container_secundario {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
  background: #eee;
  width: 70%;
  flex-wrap: wrap;
}

.navegacao {
  max-width: 780px;
  margin: 20px auto;
  overflow: auto;
  min-height: 100px;
  border: 2px solid chartreuse;
  padding: 30px;
  border-radius: 10px;    
} 

.txtEtapa {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center; 
  margin: 5px auto;
} 

.divBotoes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
} 

.buttonEtapa { 
  margin: 0px 10px; 
  background-color: chartreuse;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  width: 40%;
  font-size: 16px;
  color: #444;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
}

.button:hover {
  background-color: #444;
  color: chartreuse;
} 

.buttonSimulacao { 
  margin: 0px 10px; 
  background-color: #adff2f;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  width: 40%;
  font-size: 16px;
  color: #444;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
} 

.buttonSimulacaoCorrecao { 
  margin: 0px 10px; 
  background-color: #adff2f;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  width: 18%;
  font-size: 16px;
  color: #444;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
} 

.inputAtividade {
  font-size: large;
  font-family: monospace; 
  text-align: center; 
} 

.inputRespCorreta {
  font-size: large;
  font-family: monospace; 
  text-align: center; 
  border: 2px solid chartreuse;
}

.inputRespErrada {
  font-size: large;
  font-family: monospace; 
  text-align: center; 
  border: 2px solid tomato;
}