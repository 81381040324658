
/* usando */
.containerBotoesDefLac {
    display: flex; 
    align-items: center;
    justify-content: space-around;
}

/* usando */
.filtroBusca {
    display: flex; 
    flex-direction: column;
    width: 100%; 
    font-size: 1.8rem; 
    font-weight: 700;    
} 

/* usando */
.resultados {
    color: #fafafa;
    background-color: #320b86; 
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
}
