/* PADRÃO ORGANIZAÇÃO PÁGINAS */
.centraliza {
  display: flex;
  flex-direction: column;
  align-items: center;     
} 

/* Com espaço para o Menu principal */
.centraliza .home {
  display: flex;
  padding-top: 9.0rem;
  flex-direction: column;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  max-width: 980px;         
}

/* MENU PÁGINAS */

.containerMenuPg {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  max-width: 980px; 
  border-radius: 2rem;
  font-size: 1.8rem;
  background-color: #320b86; 
  color: #fafafa;
}

.containerMenuPg .containerPesquisa {
  display: flex;
  align-items: center; 
  color: #fafafa; 
  white-space: nowrap; 
  width: 100%;
} 

.botaoMenu {
  border: 0.2rem solid #fafafa;
  padding: 1rem; 
  margin: 2rem;
  border-radius: 2rem;
  cursor: pointer; 
  color: #fafafa;
  background-color: #320b86; 
  white-space: nowrap;
}

.botaoMenu:hover {
  text-decoration: underline;
} 

.botaoMenu:active {
  background-color: #fafafa; 
  color: #320b86;
} 

.iconeMenuPg {
  cursor: pointer; 
  margin: 2rem;
}

.iconeMenuPg:hover {
  text-decoration: underline;   
}

.iconeMenuPg:active {
  color: #320b86; 
}

/* CONFIGURAÇÕES DE TABELA */

.bordaTable {
  display: flex; 
  flex-direction: column;
  border-radius: 2rem;
  overflow: hidden;
  border: 0.4rem solid #320b86; 
  margin-top: 2rem; 
  margin-bottom: 2rem;
}

/* Usar quando a tabela apenas apresenta info e não é necessário acompanhar o cursor */
.bordaTableSemSel {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  overflow: hidden;
  border: 0.4rem solid #320b86; 
  margin-top: 2rem; 
  margin-bottom: 2rem;
}

.bordaTableSemSel tbody tr:hover { 
  background-color: #fafafa; 
  cursor: default;
}

.ttTable {
  font-weight: 900; 
  font-size: 1.8rem;
}

.destacaH1 {
  font-size: 6rem;
} 

.iconeTable {
  margin: 1rem;
  cursor: pointer;
}

.iconeTable:hover {
  color: #fafafa;    
}

.iconeTable:active {
  color: black; 
}

/* FORMULÁRIOS */

.containerBtnAcoes {
  display: flex;
  justify-content: right; 
  margin-top: 1rem; 
  margin-bottom: 1rem; 
  width: 100%;
}

.btnAcoes {
  display: block;
  border: 0.25rem solid #fafafa;
  padding: 1rem; 
  border-radius: 2rem;
  cursor: pointer; 
  color: #fafafa;
  background-color: #320b86; 
}

.btnAcoes:hover {
  text-decoration: underline; 
} 

.btnAcoes:active {
  background-color: #fafafa; 
  color: #320b86;
}

.btnAcaoSecundariaEscuro {
  display: block;
  border: 0.25rem solid #320b86;
  padding: 1rem; 
  border-radius: 2rem;
  cursor: pointer; 
  color: #fafafa;
  background-color: #320b86; 
  text-decoration: none;
}

.btnAcaoSecundariaEscuro:hover {
  text-decoration: underline;
} 

.btnAcaoSecundariaEscuro:active {
  background-color: #fafafa;
  color: #320b86;
}

.btnAcaoSecundariaClaro {
  display: block;
  border: 0.25rem solid #320b86;
  padding: 1rem; 
  border-radius: 2rem;
  cursor: pointer; 
  color: #320b86;
  background-color: #fafafa; 
  text-decoration: none;
}

.btnAcaoSecundariaClaro:hover {
  text-decoration: underline;
} 

.btnAcaoSecundariaClaro:active {
  background-color: #320b86;
  color: #fafafa;
}

.iconeForm {
  margin-left: 1rem; 
  color: #320b86; 
  cursor: pointer;
} 

.iconeForm:hover {
  color: #9a67ea;   
} 

.iconeForm:active {
  color: #320b86;
}

.btnLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.3rem solid #320b86; 
  border-radius: 2rem; 
  width: 16rem; 
  text-align: center; 
  padding-right: 1rem;
  margin-left: 1rem; 
  color: #320b86; 
  text-decoration: none;
  font-size: 1.8rem; 
  height: 4.5rem; 
  cursor: pointer; 
} 

.btnLink:hover {
  text-decoration: underline; /*usar underline no hover e cor no click*/
}

.btnLink:active {
  background-color: #320b86;
  color: #fafafa;
}

.inputIcone100 {
  display: flex; 
  align-items: center; 
  width: 100%; 
  margin-bottom: 0.5rem;
} 

.duvida {
  font-weight: 900; 
  border: 0.1rem solid #320b86; 
  padding: 0rem 0.5rem; 
  cursor: pointer;
} 

.infoDuvida { 
  appearance: none;
  position: absolute;
  border: 0.3rem solid coral; 
  color: coral; 
  font-size: 2rem;
  font-style: italic; 
  font-weight: 500;
  display: none; 
  width: 50%; 
  background-color: #fafafa; 
  border-radius: 2rem; 
  padding: 1rem;
}

.duvida:hover .infoDuvida {
  display: block;
}

/* APRESENTA RESP TESTE */
.containerFixo {
  display: flex;
  justify-content: right; 
  width: 78%; 
  align-items: center; 
  position: fixed; 
  height: '1.6rem'; 
  top: 15%;
}

.containerFixo .btnMostra {
  display: block;
  border: 0.25rem solid #fafafa;
  padding: 1rem; 
  border-radius: 2rem;
  cursor: pointer; 
  color: #fafafa;
  background-color: #320b86; 
}

.containerFixo .btnMostra:hover {
  text-decoration: underline; 
} 

.containerFixo .btnMostra:active {
  background-color: #fafafa; 
  color: #320b86;
}


/* MODAL */
/* The popup form - hidden by default */
.formPopup {
  display: none;
  position: fixed;
  z-index: 9; 
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 50rem;
  height: 30rem;
  margin-left: -25rem; /* metade da largura */
  margin-top: -15rem; /* metade da altura */
  color: #fafafa;
  border: 0.4rem solid #673ab7; 
  border-radius: 2rem;
  top: 50%; 
  left: 50%; 
  padding: 2rem; 
  background-color: #320b86;
}

.formPopup label {
  border: 0.4rem solid #fafafa;
  border-radius: 2rem; 
  padding: 1rem; 
  color: #fafafa;  
  cursor: pointer;
}

.formPopup label:hover {
  text-decoration: underline; 
}

.formPopup label:active {
  background-color: #fafafa;  
  border: 0.4rem solid #320b86;
  color: #320b86;
}

/* ORGANIZAR COMPONENTES EM COLUNAS */

.colunas { 
  display: flex;
}

.lrg80 {
  display: flex;    
  align-items: center;
  width: 80%; 
} 

.lrg75 {
  display: flex;    
  align-items: center;
  width: 75%; 
}

.lrg50 {
  display: flex;    
  align-items: center;
  width: 50%; 
}

.lrg40 {
  display: flex;    
  align-items: center;
  width: 40%; 
}

.lrg35 {
  display: flex;    
  align-items: center;
  width: 35%; 
}

.lrg33 {
  display: flex;    
  align-items: center;
  width: 33.33%; 
}

.lrg30 {
  display: flex;    
  align-items: center;
  width: 30%;
}

.lrg25 {
  display: flex;    
  align-items: center;
  width: 25%;
}

.lrg20 {
  display: flex;    
  align-items: center;
  width: 20%;
}

/* TESTES */

.textoTeste {
  /*display: flex;*/
  border-radius: 2rem;
  /*border: 0.4rem solid #320b86;   */
  padding: 2rem;
  width: 100%; 
  margin: 2rem 0rem; 
} 

.textoTeste label {
  font-size: 2.5rem; 
  font-weight: normal; 
  font-family: monospace;; 
  letter-spacing: normal; /*0.06?*/ 
  cursor: pointer; 
  margin-bottom: 0.5rem;
} 

.textoTeste .escolherPlv {
  cursor: pointer;
} 

.textoTeste .plvSel {
  cursor: pointer;
  color:#9a67ea;
} 

.textoTeste input {
  font-size: 2.5rem;
  font-family: monospace; 
  text-align: center; 
  border: 0.25rem solid black; 
  padding: 0rem; 
  margin: 0rem;
  border-radius: 0rem; 
  letter-spacing: normal;
  cursor: pointer; 
  margin-bottom: 1rem;
} 

.textoTeste .inputRespCorreta {
  border: 0.25rem solid chartreuse;
}

.textoTeste .inputRespErrada {
  border: 0.25rem solid tomato;
}

.txt_lbl_s {
  color: lightblue;
  font-size: 1.8rem;
  font-family: monospace;
}

.txt_lbl_n {
  /*color: lightblue;*/
  font-size: 1.8rem;
  font-family: monospace;
}

.txt_lbl_espaco {
  font-size: 1.8rem;
  font-family: monospace;
}

/* uso textArea sem ser teste */
.fonteTxa {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  font-size: 1.8rem; 
  line-height: normal; 
  font-weight: normal; 
  max-height: 22rem; 
  border: 0rem; 
  overflow: auto; 
  background-color: #fafafa; 
  resize: none; 
  cursor: default;
}

/* RESPONSIVIDADE */

@media (max-width: 920px) {
  html {
      font-size: 57%;
  }
}

@media (max-width: 700px) {
  html {
      font-size: 55%;
  }
}

@media (max-width: 600px) {
  .containerMenuPg {
    flex-direction: column; 
    align-items: flex-start;
  }
  .containerMenuPg .containerPesquisa label {
    margin-left: 2rem;
  } 
  .containerMenuPg .containerPesquisa input[type=text] {
    margin-right: 2rem;
    margin-left: 2rem; 
    width: 92%;
  }
  .containerMenuPg .containerPesquisa input[type=radio] {
    margin-left: 2rem;
  } 
}

@media (max-width: 500px) {
  html {
      font-size: 50%;
  }
}

@media (max-width: 400px) {
  .containerMenuPg .containerPesquisa {
    flex-direction: column; 
    align-items: flex-start;
  } 
}

@media (max-width: 330px) {
  html {
      font-size: 45%;
  }
}

/*
@media (max-width: 600px) {
  html {
      font-size: 40%;
  }
}

@media (max-width: 530px) {
  html {
      font-size: 35%;
  }
}

@media (max-width: 400px) {
  html {
      font-size: 30%;
  }
}
*/