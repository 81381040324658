* {
  box-sizing: border-box;
	margin: 0px;
	padding: 0px; 
  outline: 0;
}

html,
body {
  height: 100vh;
}

html {
  scroll-behavior: smooth;
  /* A cada 1rem será considerado 10px */
  font-size: 62.5%;
}

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* equivalente a um padrão de 16x */
  font-size: 1.6rem;
}

/* Preenche todo o fundo com a cor */ 
body {
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-weight: 900;
  font-size: 4.5rem;
  color: #320b86; 
  margin-bottom: 1rem;
}

h2 {
  font-weight: 700;
  font-size: 3rem;
  color: #320b86;
}

p {
  font-weight: 500;
  font-size: 1.8rem;
  /*color: #9a67ea;*/
}

a {
  font-weight: 900;
  font-size: 1.6rem;
}
 
button {
  cursor: pointer;
}

label {
  font-size: 1.6rem; 
  font-weight: 700; 
  margin-bottom: 0.5rem; 
  line-height: 3.2rem;
}

input, select {
  border: 0.3rem solid #320b86; 
  border-radius: 2rem; 
  margin-bottom: 0.5rem; 
  padding: 1rem;
  width: 100%; 
  font-size: 1.6rem;
}

select {
  cursor: pointer;
}

option {
  background-color: #320b86; 
  color: #fafafa;   
}

textarea {
  padding: 2rem;
  width: 100%;
  line-height: 2.8rem;
  border-radius: 2rem;
  border: 0.3rem solid #320b86; 
  font-size: 1.8rem; 
  resize: none;
}

table {
  border-collapse: collapse;
  width: 100%; 
} 

thead {
  color: #320b86; 
  border-bottom: 0.4rem solid #320b86; 
}

tr {
  height: 3.2rem; 
}

tbody tr:hover {
  background-color: #9a67ea; 
  cursor: pointer;
}

tbody tr:active {
  background-color: #fafafa;
}

/* testesFixPlvDefLac */
fieldset {
  width: 100%; 
  margin: 2rem 0rem; 
  border: 0.3rem solid #320b86; 
  border-radius: 2rem;
  padding: 1rem 2rem;
  color: #320b86; 
  font-size: 1.8rem; 
  font-weight: 700;  
} 

legend {
  margin: 0rem 2rem;
  padding: 0rem 1rem;
}

/* CHECKBOX */

input[type=checkbox] {
  position: relative;
  width: 2rem;
  height: 2rem;
  cursor: pointer; 
  margin-left: 2rem; 
}

input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0rem;
  left: 0rem;
  background-color:#fafafa;
  border: 0.3rem solid #320b86;
}

input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0rem;
  left: 0rem;
  background-color:#fafafa;
  border: 0.3rem solid #320b86;
}

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 0.45rem;
  height: 0.9rem;
  border: solid #320b86;
  border-width: 0rem 0.3rem 0.3rem 0rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0.3rem;
  left: 0.7rem;
}

/* RÁDIO */
input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 0.05rem;
  height: 0.05rem;

  border: 0.4rem solid #fafafa;
  background-color: #fafafa;
  transition: 0.2s all linear;
  margin: 0rem 1rem;
  padding: 0.5rem;
  cursor: pointer;
  /*position: relative;*/
  /*top: 4px;*/
}

input[type=radio]:checked {
  border: 0.4rem solid #fafafa; 
  background-color: #320b86;  
}

/* foco */ 
input:focus {
  border-color: #9a67ea;
}

option:focus {
  border-color: #9a67ea;  
} 

select:focus {
  border-color: #9a67ea;  
} 

textarea:focus {
  border-color: #9a67ea;  
}

/* desabilitado */ 
input:disabled {
  background-color: #dddddd;
}

option:disabled {
  border-color: #dddddd;  
} 

select:disabled {
  border-color: #dddddd;  
} 

textarea:disabled {
  border-color: #dddddd;  
}